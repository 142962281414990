import React from 'react'
import { Redirect } from 'react-router'

export default function NotFound() {
   
    return (
        <>
            <Redirect to="/" />
        </>
    )
}
